import React from 'react'
import {awards, groupBibEntries} from '../utils/bibliography'

import locales from '../utils/strings'
import styles from '../templates/basic.module.less'
import Layout from "../components/layout";

const entryGroups = groupBibEntries(awards)

const PublicationsPage = ({ location, pathContext }) => {
  const {lang} = pathContext

  if (!locales[lang]) {
    throw new Error(`Unknown language: ${lang}.`)
  }

  const strings = locales[lang].awards

  return <Layout location={location}>
    <div>
      <h1 className={styles.title}>{strings.title}</h1>
      {
        entryGroups.map(([year, subgroups]) => (
          <div key={year}>
            <h2>{strings.yearHeading.replace('{}', year)}</h2>
            <ul>
              {
                subgroups.map(([myType, entries]) => <div key={myType}>
                    { entries.map(entry => <PublicationEntry entry={entry} key={entry.id} />) }
                </div>)
              }
            </ul>
          </div>
        ))
      }
    </div>
  </Layout>
}

const PublicationEntry = ({ entry }) => {
  let html = entry.awardee
  const sep = ', '

  html += sep + `<b>${entry.award}</b>`

  if (entry.title) {
    html += sep + entry.title
  }

  const {year, month, date} = entry
  html += sep + `${year}.${month}.${date}`

  html += '.'

  return <li dangerouslySetInnerHTML={{__html: html}} />
}

export default PublicationsPage
